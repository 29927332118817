import React from 'react'
import { Container, ThemeProvider } from '@mui/system'
import { theme } from '../utils/theme'
import CssBaseline from '@mui/material/CssBaseline'
import Header from './Header'
import Footer from './Footer'
import Card from './Card'

const Layout = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Header />
        <Card file={props.file}>{props.children}</Card>
        <Footer />
      </Container>
    </ThemeProvider>
  )
}

export default Layout
