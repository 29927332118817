import React from 'react'
import { Grid, Link, Typography } from '@mui/material'

const Footer = () => {
  return (
    <Grid
      container
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      sx={{
        color: '#12263C',
        ['@media (max-width:686px)']: {
          // eslint-disable-line no-useless-computed-key
          px: 2,
        },
      }}
    >
      <Grid item mt={3} mb={5}>
        <Typography
          variant="body2"
          component="p"
          textAlign={'center'}
          color="#1F4A6F"
        >
          Du har et selvstendig ansvar for at reklamen er merket riktig etter
          regelverket. Denne tjenesten er kun et hjelpemiddel. For mer
          informasjon, se{' '}
          <Link
            color="inherit"
            underline="always"
            href="https://www.forbrukertilsynet.no/forbrukertilsynets-veiledning-om-merking-av-retusjert-reklame"
          >
            Forbrukertilsynets veiledning om merking av retusjert reklame
          </Link>{' '}
          eller{' '}
          <Link
            color="inherit"
            underline="always"
            href="mailto:post@forbrukertilsynet.no"
          >
            Kontakt oss.
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Footer
