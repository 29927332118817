// To overcome browser restrications on canvas width and hight (for panorama picture)
export const limitSize = (size, maximumPixels) => {
  const { width, height } = size

  const requiredPixels = width * height
  if (requiredPixels <= maximumPixels) return { width, height }

  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels)
  return {
    width: Math.floor(width * scalar),
    height: Math.floor(height * scalar),
  }
}

export const getVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src)
      resolve(video.duration)
    }
    video.src = URL.createObjectURL(file)
  })
}

export const getImageData = (img) => {
  return new Promise((resolve, reject) => {
    img.onload = () => {
      console.log('image loaded', img.name)
      resolve({
        height: img.height,
        width: img.width,
      })
    }
    img.onerror = (error) => {
      console.log('image error', img.name, error)
      reject(error)
    }
  })
}

export const isBrowserSupported = (browser) => {
  const isValidBrowser =
    browser.includes('Chrome') ||
    browser.includes('Safari') ||
    browser.includes('Firefox') ||
    browser.includes('Microsoft Edge')
  return isValidBrowser
}

export const isBrowserIosChrome = (platform) => {
  return (
    platform.name.includes('Chrome') && platform.os.toString().includes('iOS')
  )
}

export const toObjectUrl = (canvas, type) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(function (blob) {
      resolve(URL.createObjectURL(blob))
    }, type)
  })
}
