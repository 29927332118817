import React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const AlertDialog = ({ isOpen, handleCloseDialog, message }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="error"
      fullWidth
      style={{
        borderRadius: '18px',
      }}
    >
      <Box pt={1}>
        <ErrorOutlineIcon
          style={{ color: '#F34D44', width: '32px', height: '32px' }}
        />
      </Box>
      <DialogTitle
        sx={{
          color: '#12263C',
          fontSize: 26,
          paddingTop: 0,
          paddingBottom: '4px',
        }}
      >
        {message.title}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 2 }}>
        <DialogContentText
          sx={{
            color: '#12263C',
            letterSpacing: '-0.5px',
          }}
        >
          {message.text}
        </DialogContentText>
      </DialogContent>
      {handleCloseDialog && (
        <DialogActions justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            onClick={handleCloseDialog}
            size="large"
            sx={{
              textTransform: 'inherit',
            }}
          >
            Prøv igjen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default AlertDialog
