import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    color: {
      default: "#12263C",
    },
    background: {
      default: "#F6F6F6",
    },
    primary: {
      main: "#1F4A6F",
      dark: "#0F2D46",
    },
    secondary: {
      main: "#DBE6EC",
      dark: "#C3D7E1",
    },
  },
  typography: {
    fontFamily: [
      'aktiv-grotesk',
    ],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 686,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: '18px',
          height: 60,
          minWidth: 295,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '@media (max-width: 686px)': {
            minWidth: 'auto',
            maxWidth: 295,
            width: '100%'
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          '&:hover': {
            color: "#F34D44",
         },
        },
      },
    },
    MuiDialog:{
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(31, 74, 111, 0.8)',
          textAlign: 'center',
        },
        paper: {
          borderRadius: '18px',
          padding: 24,
          '@media (max-width: 686px)': {
            margin: 16,
            width: '100%'
          },
        }
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center'
        },
      },
    }
  }
});
