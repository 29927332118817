import {
  Button,
  Grid,
  Typography,
  CardContent,
  Box,
  CircularProgress,
} from '@mui/material'
import init, { open_image, putImageData, watermark } from 'photon-web'
import React, { useState, useRef, useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import '../App.css'
import Toggle from '../components/Toggle'
import AlertDialog from '../components/AlertDialog'
import {
  getImageData,
  getVideoDuration,
  limitSize,
  isBrowserSupported,
  isBrowserIosChrome,
  toObjectUrl,
} from '../utils/helper'
import Layout from '../components/Layout'
import platform from 'platform'

function Home() {
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [downloadLink, setDownloadLink] = useState('')
  const [mode, setMode] = useState('light')
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [unSupportedBrowser, setUnSupportedBrowser] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const fileInput = useRef(null)

  useEffect(() => {
    if (!isBrowserSupported(platform.name)) {
      setErrorMessage({
        title: 'Nettleser støttes ikke',
        text: 'Beklager, vi støtter ikke gjeldende nettleser ennå.',
      })

      setUnSupportedBrowser(true)
    }
  }, [])

  const watermarkVideo = async (video) => {
    const headers = new Headers()
    headers.append('Content-Type', 'video/*')
    // headers.append('Access-Control-Allow-Origin', '*')
    const res = await fetch(
      `https://server.watermark.frontkom.io/?mode=${mode}`,
      {
        method: 'POST',
        body: video,
        headers,
        redirect: 'follow',
      }
    )

    const file = await res.blob()
    var url = window.URL.createObjectURL(file)

    setDownloadLink(url)
    setLoading(false)
  }

  const addWatermark = async (imageFile) => {
    return Promise.resolve(
      init().then(async () => {
        // watermark image
        const watermark_img = document.createElement('img')
        watermark_img.src = `/water_mark_${mode}.svg`
        // source image
        let srcimg = new Image()
        srcimg.src = URL.createObjectURL(imageFile)
        srcimg.crossOrigin = 'anonymous'

        let imageData = { height: 0, width: 0 }
        if (srcimg.height) {
          imageData = { height: srcimg.height, width: srcimg.width }
        } else {
          imageData = await getImageData(srcimg)
        }

        let watermarkImageData = { height: 0, width: 0 }
        if (watermark_img.height) {
          watermarkImageData = {
            height: watermark_img.height,
            width: watermark_img.width,
          }
        } else {
          watermarkImageData = await getImageData(watermark_img)
        }

        // main canvas to draw
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Safari limitation on max canvas wdith and height https://pqina.nl/blog/canvas-area-exceeds-the-maximum-limit/
        if (platform.name.includes('Safari')) {
          canvas.height = limitSize(imageData, 16777216).height
          canvas.width = limitSize(imageData, 16777216).width
          ctx.drawImage(
            srcimg,
            0,
            0,
            limitSize(imageData, 16777216).width,
            limitSize(imageData, 16777216).height
          )
        } else {
          canvas.height = imageData.height
          canvas.width = imageData.width
          ctx.drawImage(srcimg, 0, 0)
        }

        // Draw the image element onto the canvas

        // setup watermark
        const watermark_canvas = document.createElement('canvas')

        let imageArea = canvas.height * canvas.width
        let waterMarkArea = (imageArea * 7) / 100

        waterMarkArea = Math.sqrt(waterMarkArea)

        watermark_canvas.width = waterMarkArea
        watermark_canvas.height = waterMarkArea

        const watermark_ctx = watermark_canvas.getContext('2d')

        watermark_ctx.drawImage(
          watermark_img,
          0,
          0,
          waterMarkArea,
          waterMarkArea
        ) // destination rectangle

        // Convert the ImageData found in the canvas to a PhotonImage (so that it can communicate with the core Rust library)
        let src_photon_img = open_image(canvas, ctx)

        let watermark_photon_img = open_image(watermark_canvas, watermark_ctx)

        // add watermark
        watermark(
          src_photon_img,
          watermark_photon_img,
          20,
          // srcimg.height - watermark_img.height - 20
          20
        )

        putImageData(canvas, ctx, src_photon_img)

        // canvas.width = imageData.width
        // canvas.height = imageData.height

        const url = await toObjectUrl(canvas, imageFile.type)

        setDownloadLink(url)
        return
      })
    )
  }

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    setFile(null)
    setDownloadLink('')
    const fileUploaded = event.target.files[0]
    const maxAllowedSize = 250 * 1024 * 1024
    setFile(fileUploaded)
    setLoading(true)
    if (fileUploaded.size > maxAllowedSize) {
      setIsOpenDialog(true)
      setErrorMessage({
        title: 'Filen er for stor',
        text: 'Beklager, filen du forsøker å laste opp er for stor (max størrelse er 250MB).',
      })
      setFile(null)
      setDownloadLink(false)

      return
    }
    let _paq = (window._paq = window._paq || [])

    if (fileUploaded.type.split('/')[0] === 'image') {
      _paq.push(['trackEvent', 'Media', 'Upload', 'Image'])

      await addWatermark(fileUploaded)

      setLoading(false)
      return
    }
    if (fileUploaded.type.split('/')[0] === 'video') {
      _paq.push(['trackEvent', 'Contact', 'Upload', 'Video'])

      const duration = await getVideoDuration(fileUploaded)
      if (duration > 60 * 5) {
        setIsOpenDialog(true)
        setErrorMessage({
          title: 'Videoen er for lang',
          text: 'Beklager, videoen du forsøker å laste opp er for lang (max lengde er 5 minutter).',
        })
        setFile(null)
        setDownloadLink(false)

        return
      }
      await watermarkVideo(fileUploaded)
      setLoading(false)
      return
    }
  }

  const handleChangeMode = (value) => {
    if (value) setMode(value)
  }

  const handleCloseDialog = () => {
    setIsOpenDialog(false)
  }

  return (
    <Layout file={file}>
      <CardContent
        sx={{
          px: '24px',
          py: '10px',
          borderBottom: file ? '' : '1px solid #EDEDED',
          ['@media (min-width:686px)']: {
            // eslint-disable-line no-useless-computed-key
            px: '48px',
            py: '18px',
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          style={{ color: '#12263C' }}
        >
          <Grid item>
            <Box mt={2} mb={2}>
              <Typography
                variant="h4"
                component="div"
                textAlign={'center'}
                sx={{
                  fontSize: '26px',
                  fontWeight: '500',
                  ['@media (min-width:686px)']: {
                    // eslint-disable-line no-useless-computed-key
                    fontSize: '32px',
                  },
                }}
              >
                Merking av retusjert reklame
              </Typography>
            </Box>
          </Grid>
          {!file && (
            <>
              <Grid item>
                <Box mt={1}>
                  <Typography
                    variant="body1"
                    component="p"
                    textAlign={'center'}
                    sx={{
                      letterSpacing: '-0.5px',
                      margin: '10px 0',
                    }}
                  >
                    Dette er en tjeneste som merker bildet/videoen for deg.
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    textAlign={'center'}
                    sx={{
                      letterSpacing: '-0.5px',
                      margin: '10px 0',
                    }}
                  >
                    Merket vil bli plassert øverst i venstre hjørne. Velg det
                    merket som gir best synlighet til bakgrunnen.
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    textAlign={'center'}
                    sx={{
                      letterSpacing: '-0.5px',
                      margin: '10px 0',
                    }}
                  >
                    Merk at du kan kun laste opp og ned én fil av gangen.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={4}>
                  <Typography
                    variant="body2"
                    component="p"
                    textAlign={'center'}
                    sx={{
                      fontWeight: 500,
                      marginBottom: '10px',
                    }}
                  >
                    Velg merke
                  </Typography>
                  <Toggle mode={mode} onChangeMode={handleChangeMode} />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardContent style={{ padding: 24, paddingTop: 10, paddingBottom: 30 }}>
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          {!file && (
            <>
              <Grid item style={{ width: '100%', textAlign: 'center' }}>
                <Box mt={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => fileInput.current.click()}
                    size="large"
                    startIcon={<AddCircleIcon style={{ marginRight: 8 }} />}
                    sx={{
                      textTransform: 'inherit',
                    }}
                  >
                    Last opp din fil
                  </Button>
                  <input
                    type="file"
                    ref={fileInput}
                    onChange={handleChange}
                    onClick={(event) => {
                      event.target.value = null
                    }}
                    style={{ display: 'none' }}
                    accept="image/x-png,image/jpeg, image/jpg,video/*"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2} style={{ color: '#546B85' }}>
                  <Typography
                    variant="body2"
                    component="div"
                    textAlign={'center'}
                  >
                    Støttede filtyper: JPG, PNG, MOV, MP4
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    textAlign={'center'}
                    sx={{
                      marginTop: '6px',
                    }}
                  >
                    Maksimal filstørrelse er 250MB og 5 minutter lang
                  </Typography>
                </Box>
              </Grid>
            </>
          )}

          {file && (
            <>
              <Grid item style={{ width: '100%' }}>
                <Box
                  mt={3}
                  px={2}
                  py={2}
                  sx={{
                    backgroundColor: '#D9F6DC',
                    borderRadius: '15px',
                    border: '1px solid #C6F2CA',
                    width: 295,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    ['@media (max-width:686px)']: {
                      // eslint-disable-line no-useless-computed-key
                      minWidth: 'auto',
                      width: '100%',
                      maxWidth: 295,
                    },
                  }}
                >
                  <Grid container flexDirection={'row'} alignItems="center">
                    <Grid item xs={2}>
                      {loading ? (
                        <CircularProgress size={25} style={{ color: '#000' }} />
                      ) : (
                        <CheckCircleIcon style={{ color: '#68D572' }} />
                      )}
                    </Grid>
                    <Grid item xs={9}>
                      <Typography style={{ wordBreak: 'break-word' }}>
                        {file.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {downloadLink && (
                <Grid
                  container
                  style={{ width: '100%' }}
                  justifyContent="center"
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <Grid item style={{ width: '100%', textAlign: 'center' }}>
                    <Box mt={3}>
                      <Typography
                        variant="body1"
                        component="p"
                        textAlign={'center'}
                        color="#1F4A6F"
                        mb={3}
                      >
                        Filen din er klar!
                      </Typography>

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={async () => {
                          if (isBrowserIosChrome(platform)) {
                            if (file.type.split('/')[0] === 'image') {
                              let w = window.open('about:blank')
                              let image = new Image()
                              image.src = downloadLink
                              setTimeout(function () {
                                w.document.write(image.outerHTML)
                              }, 0)
                            }

                            if (file.type.split('/')[0] === 'video') {
                              var link = document.createElement('a')
                              link.href = downloadLink
                              link.download = file.name
                              link.target = '_blank'
                              document.body.appendChild(link)

                              link.click()
                              document.body.removeChild(link)
                            }
                            return
                          }

                          var link = document.createElement('a')
                          link.href = downloadLink
                          link.download = file.name
                          document.body.appendChild(link)

                          link.click()
                          document.body.removeChild(link)
                        }}
                        sx={{
                          textTransform: 'inherit',
                        }}
                      >
                        Last ned fil
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item style={{ width: '100%', textAlign: 'center' }}>
                    <Box mt={3}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          setFile(null)
                          setDownloadLink('')
                        }}
                        sx={{
                          textTransform: 'inherit',
                        }}
                      >
                        Last opp en ny fil
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
      <AlertDialog
        isOpen={isOpenDialog}
        handleCloseDialog={handleCloseDialog}
        message={errorMessage}
      />

      <AlertDialog
        isOpen={unSupportedBrowser}
        handleCloseDialog={undefined}
        message={errorMessage}
      />
    </Layout>
  )
}

export default Home
