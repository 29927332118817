import { Card as MuiCard } from '@mui/material'
import React from 'react'

const Card = (props) => {
  return (
    <MuiCard
      variant="outlined"
      sx={{
        backgroundColor: '#FFFFFF',
        border: 0,
        borderRadius: '18px',
        boxShadow:
          '0px 9px 191px rgba(136, 150, 155, 0.05), 0px 3.75998px 79.7952px rgba(136, 150, 155, 0.0359427), 0px 2.01027px 42.6623px rgba(136, 150, 155, 0.0298054), 0px 1.12694px 23.9162px rgba(136, 150, 155, 0.025), 0px 0.598509px 12.7017px rgba(136, 150, 155, 0.0201946), 0px 0.249053px 5.28546px rgba(136, 150, 155, 0.0140573)',
        mx: '16px',
        ['@media (min-width:686px)']: {
          // eslint-disable-line no-useless-computed-key
          mx: '0',
        },
      }}
    >
      {props.children}
    </MuiCard>
  )
}

export default Card
