import React from 'react'
import { Link as RedirectLink } from 'react-router-dom'
import { Box, CardContent, Grid, Link, Typography } from '@mui/material'

import Layout from '../components/Layout'

const NoPage = () => {
  return (
    <Layout>
      <CardContent
        sx={{
          px: '24px',
          py: '10px',
          ['@media (min-width:686px)']: {
            // eslint-disable-line no-useless-computed-key
            px: '78px',
            py: '18px',
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          style={{ color: '#12263C' }}
        >
          <Grid item>
            <Box mt={2}>
              <Typography
                variant="h1"
                component="div"
                textAlign={'center'}
                sx={{
                  color: '#DBE6EC',
                  fontSize: '79px',
                  fontWeight: '700',
                }}
              >
                404
              </Typography>
              <Typography
                variant="h4"
                component="div"
                textAlign={'center'}
                sx={{
                  color: '#12263C',
                  fontSize: '32px',
                  fontWeight: '500',
                  lineHeight: '1.1',
                }}
              >
                Side ikke funnet
              </Typography>
              <Typography
                variant="body"
                component="div"
                textAlign={'center'}
                sx={{
                  color: '#12263C',
                  px: '142px',
                  lineHeight: '1.41',
                  marginTop: '13px',
                  ['@media (max-width:685px)']: {
                    // eslint-disable-line no-useless-computed-key
                    px: '20px',
                  },
                }}
              >
                Beklager, siden du lette etter eksisterer ikke.
              </Typography>
              <Typography
                variant="body"
                component="div"
                textAlign={'center'}
                sx={{
                  color: '#12263C',
                  marginTop: '16px',
                  marginBottom: '32px',
                }}
              >
                Gå{' '}
                <Link
                  component={RedirectLink}
                  to="/"
                  color="inherit"
                  underline="always"
                >
                  hjem
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Layout>
  )
}

export default NoPage
