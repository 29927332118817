import React from 'react'
import { styled } from '@mui/material/styles'
import { ToggleButton, ToggleButtonGroup, Paper } from '@mui/material'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    '&.MuiToggleButton-root': {
      border: 0,
      borderRadius: '9px',
      color: '#12263C',
      width: '50%',
    },
    '&.MuiToggleButton-root:not(.Mui-selected)': {
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&.MuiToggleButton-root:not(&.Mui-selected):hover': {
      backgroundColor: '#C6D3DC',
    },
    '&.Mui-selected': {
      backgroundColor: '#1F4A6F',
      color: '#FFFFFF',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#1F4A6F',
    },
  },
}))

const Toggle = ({ mode, onChangeMode }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        border: 0,
        borderRadius: '15px',
        backgroundColor: '#DBE6EC',
        minWidth: '236px',
        padding: '6px',
        marginBottom: '4px',
      }}
    >
      <StyledToggleButtonGroup
        value={mode}
        exclusive
        onChange={(event, mode) => {
          onChangeMode(mode)
        }}
        aria-label="text alignment"
        sx={{
          display: 'flex',
          gap: 1,
          height: '42px',
        }}
      >
        <ToggleButton value="light" sx={{ textTransform: 'capitalize' }}>
          Hvitt
        </ToggleButton>
        <ToggleButton value="dark" style={{ textTransform: 'capitalize' }}>
          Svart
        </ToggleButton>
      </StyledToggleButtonGroup>
    </Paper>
  )
}

export default Toggle
