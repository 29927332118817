import React from 'react'
import { Grid, useMediaQuery } from '@mui/material'

const Header = () => {
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <Grid container justifyContent="center">
      <Grid item mt={4} mb={isMobile ? 3 : 5}>
        <a href="http://forbrukertilsynet.no">
          <img
            height={37}
            width={230}
            src="/logo.svg"
            alt="forbrukertilsynet"
          />
        </a>
      </Grid>
    </Grid>
  )
}

export default Header
